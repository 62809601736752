import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Route, Switch } from 'react-router-dom';
import { apiEndpoint } from './prismic-configuration';

import './css/app.css';
import ScrollToTop from './util/ScrollToTop';

// import Components
import Header from './components/Header';
import Footer from './components/Footer';
import Alert from './components/Alert';

// import Pages
import Home from './pages/home/Home';
import Contact from './pages/contact/Contact';
import Property from './pages/properties/Property';
import Properties from './pages/properties/Properties';
import { Preview, NotFound } from './pages';

/**
 * Main application componenet
 */
const App = (props) => {
  const [ alertShowing, setAlertShowing ] = useState(false);
  const repoNameArray = /([^/]+)\.cdn.prismic\.io\/api/.exec(apiEndpoint);
  const repoName = repoNameArray[1];


  return (
    <div className="relative">
      <Helmet>
        <script async defer src={`//static.cdn.prismic.io/prismic.js?repo=${repoName}&new=true`} />
      </Helmet>
      <ScrollToTop />

      <Header />
      
      <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/contact' render={(props) => <Contact {...props} setAlertShowing={setAlertShowing} />} />
        <Route exact path='/properties' component={Properties} />
        <Route exact path='/properties/:propertyId' component={Property} />
        <Route exact path='/preview' component={Preview} />
        <Route component={NotFound} />
      </Switch>

      <Alert type="success" isShowing={alertShowing} setShowing={setAlertShowing} />
      <Footer />
    </div>
  )
}

export default App
