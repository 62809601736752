import React from 'react';

function SimpleEmailCapture({ addedClasses, btnText = "Subscribe", btnColor = "indigo", btnHue = "500" }) {
  const colorHueMap = {
    bg: btnHue,
    light: (parseInt(btnHue) - 100).toString(),
  };

  return (
    <form method="POST" action="https://uslandshop.activehosted.com/proc.php" class={`sm:flex ${addedClasses}`} id="_form_1_" novalidate>
      <input type="hidden" name="u" value="1" />
      <input type="hidden" name="f" value="1" />
      <input type="hidden" name="s" />
      <input type="hidden" name="c" value="0" />
      <input type="hidden" name="m" value="0" />
      <input type="hidden" name="act" value="sub" />
      <input type="hidden" name="v" value="2" />

      <input 
        required
        type="email" 
        name="email"
        aria-label="Email address" 
        placeholder="Enter your email" 
        class="appearance-none w-full px-5 py-3 border border-transparent text-base leading-6 rounded-md text-gray-900 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 transition duration-150 ease-in-out" 
      />
      <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
        <button id="_form_1_submit" type="submit" class={`w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-${btnColor}-${colorHueMap.bg} hover:bg-${btnColor}-${colorHueMap.light} focus:outline-none focus:bg-${btnColor}-${colorHueMap.light} transition duration-150 ease-in-out`}>
          { btnText }
        </button>
      </div>
    </form>
  )
}

export default SimpleEmailCapture;
