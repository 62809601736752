import React, { useState, useEffect } from 'react';
import Prismic from 'prismic-javascript';
import { RichText } from 'prismic-reactjs';
import Transition from '../../util/Transition';
import { client, linkResolver } from '../../prismic-configuration';

// import { testimonials } from '../../site.config';

function Testimonials() {
  const [ testimonials, setTestimonials ] = useState([]);
  const [ testimonialIndex, setTestimonialIndex] = useState(0);

  useEffect(() => {
    const fetchTestimonials = async() => {
      const type = 'testimonial';
      const response = await client.query(
        Prismic.Predicates.at( 'document.type', type )
      );

      if (response) {
        console.log(response);
        return setTestimonials(response.results);
      } else {
        console.warn(`No documents were found of type ${type}.`);
      }
    };

    // results[0].data.images[0].image.thumbnail.url

    fetchTestimonials();
  }, []);

  /**
   * Refactor navigate functions into utils file
   * Testimonials navigate + Property navigate
   */
  const navigatePrev = () => {
    if (testimonialIndex > 0) return setTestimonialIndex(testimonialIndex - 1);
    return setTestimonialIndex(testimonials.length - 1);
  };
  const navigateNext = () => {
    if (testimonialIndex < testimonials.length - 1) return setTestimonialIndex(testimonialIndex + 1);
    return setTestimonialIndex(0);
  };

  if (testimonials.length > 0) {
    return (
      <section class="bg-white overflow-y-hidden overflow-x-visible">
        <div class="relative max-w-screen-xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
          <div class="text-center">
            <h2 class="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
              Customer Testimonials
            </h2>
            <p class="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-500 sm:mt-4">
              See why so many others love doing business with us!
            </p>
          </div>

          <svg class="absolute top-full left-0 transform translate-x-80 -translate-y-24 lg:hidden" width="784" height="404" fill="none" viewBox="0 0 784 404">
            <defs>
              <pattern id="e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="784" height="404" fill="url(#e56e3f81-d9c1-4b83-a3ba-0d0ac8c32f32)" />
          </svg>

          <svg class="hidden lg:block absolute right-full top-1/2 transform translate-x-1/2 -translate-y-1/2" width="404" height="784" fill="none" viewBox="0 0 404 784">
            <defs>
              <pattern id="56409614-3d62-4985-9a10-7ca758a8f4f0" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="404" height="784" fill="url(#56409614-3d62-4985-9a10-7ca758a8f4f0)" />
          </svg>

          <div className="relative mt-10 lg:mt-16 flex flex-col md:flex-row md:justify-between">
            <div class="h-64">
              {testimonials.map((t, index) => {
                return (
                  <Testimonial index={index} testimonial={t.data} testimonialIndex={testimonialIndex} />
                );
              })}
            </div>

            <div className="z-50 mt-8 md:mt-0 w-full px-5 lg:px-0 md:flex md:justify-between">
              <button onClick={navigatePrev} className="cursor-pointer md:mr-auto outline-none focus:outline-none">
                <svg className="h-6 w-6 lg:h-8 lg:w-8 text-gray-600 hover:text-gray-400 transition duration-300 ease-in-out" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M15 19l-7-7 7-7"></path></svg>
              </button>
              <button onClick={navigateNext} className="cursor-pointer md:ml-auto ml-10 outline-none focus:outline-none">
                <svg className="h-6 w-6 lg:h-8 lg:w-8 text-gray-600 hover:text-gray-400 transition duration-300 ease-in-out" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M9 5l7 7-7 7"></path></svg>
              </button>
            </div>
          </div>
        </div>
      </section>
    )
  } else {
    return null;
  }
}

export default Testimonials;

function Testimonial({ index, testimonial, testimonialIndex }) {
  const { quote, name, description, image } = testimonial;

  return (
    <Transition
      show={index === testimonialIndex}
      enter="delay-300 duration-300 ease-in-out transform"
      enterFrom="opacity-0 -translate-x-64"
      enterTo="opacity-100 translate-x-0"

      leave="duration-300 ease-in-out transform"
      leaveFrom="opacity-100 translate-x-0"
      leaveTo="opacity-0 translate-x-64"
    >
      <article class="absolute inset-0 lg:flex lg:items-center px-4 md:px-12">
        <div class="hidden lg:block lg:flex-shrink-0">
          <img class="h-64 w-64 rounded-full xl:h-80 xl:w-80" src={image.url} alt={`${name} Testimonial Avatar`} />
        </div>
        <div class="relative lg:ml-10">
          <svg class="absolute top-1 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-indigo-200 opacity-50" stroke="currentColor" fill="none" viewBox="0 0 144 144">
            <path stroke-width="2" d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z" />
          </svg>
          <blockquote class="relative">
            <div class="text-xl lg:text-2xl leading-9 font-medium text-gray-900">
              <p>
                { RichText.render(quote, linkResolver) }
              </p>
            </div>
            <footer class="mt-8">
              <div class="flex">
                <div class="flex-shrink-0 lg:hidden">
                  <img class="h-12 w-12 rounded-full" src={image.mobile.url} alt="" />
                </div>
                <div class="ml-4 lg:ml-0">
                  <div class="text-base leading-6 font-medium text-gray-900">
                    { name }
                  </div>
                  <div class="text-base leading-6 font-medium text-indigo-600">
                    { description }
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </article>
    </Transition>
  );
}
