import React from 'react';

function ContactFormCapture({ setAlertShowing }) {
  return (
    <form method="POST" action="https://uslandshop.activehosted.com/proc.php" id="_form_3_" class="mt-9 grid grid-cols-1 row-gap-6 sm:grid-cols-2 sm:col-gap-8" novalidate>
      <input type="hidden" name="u" value="3" />
      <input type="hidden" name="f" value="3" />
      <input type="hidden" name="s" />
      <input type="hidden" name="c" value="0" />
      <input type="hidden" name="m" value="0" />
      <input type="hidden" name="act" value="sub" />
      <input type="hidden" name="v" value="2" />

      <div>
        <label for="first_name" class="block text-sm font-medium leading-5 text-gray-700">
          First name*
        </label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <input
            required 
            type="text"
            id="first_name" 
            name="firstname"
            class="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5" 
          />
        </div>
      </div>

      <div>
        <label for="last_name" class="block text-sm font-medium leading-5 text-gray-700">
          Last name*
        </label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <input 
            required
            id="last_name" 
            type="text" 
            name="lastname" 
            class="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5" 
          />
        </div>
      </div>

      <div class="sm:col-span-2">
        <label for="email" class="block text-sm font-medium leading-5 text-gray-700">
          Email*
        </label>
        <div class="mt-1 relative rounded-md shadow-sm">
          <input 
            required
            id="email" 
            type="email"          
            name="email" 
            class="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5" 
          />
        </div>
      </div>

      <div class="sm:col-span-2">
        <div class="flex justify-between">
          <label for="phone" class="block text-sm font-medium leading-5 text-gray-700">
            Phone
          </label>
          <span class="text-sm leading-5 text-gray-500">Optional
          </span>
        </div>
        <div class="mt-1 relative rounded-md shadow-sm">
          <input 
            id="phone"
            name="phone"  
            class="form-input block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5" 
          />
        </div>
      </div>

      <div class="sm:col-span-2">
        <div class="flex justify-between">
          <label for="how_can_we_help" class="block text-sm font-medium leading-5 text-gray-700">
            How can we help you?*
          </label>
          <span class="text-sm leading-5 text-gray-500">Max. 500 characters
          </span>
        </div>
        <div class="mt-1 relative rounded-md shadow-sm">
          <textarea
            required 
            rows="4"
            name="field[1]" 
            id="how_can_we_help" 
            class="form-textarea block w-full transition ease-in-out duration-150 sm:text-sm sm:leading-5">
          </textarea>
        </div>
      </div>
      
      <div class="text-right sm:col-span-2">
        <span class="inline-flex rounded-md shadow-sm">
          <button id="_form_3_submit" type="submit" class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out">
            Submit
          </button>
        </span>
      </div>
    </form>
  )
}

export default ContactFormCapture;