import React, { useState, useEffect } from 'react';
import { RichText } from 'prismic-reactjs';
import { client, linkResolver } from '../../prismic-configuration';

import './Property.css';
import NotFound from '../NotFound';
import Transition from '../../util/Transition';
import SimpleEmailCapture from '../../components/email/SimpleEmailCapture';

function Property(props) {
  const uid = props.match.params.propertyId;
  const [ property, setProperty ] = useState(null);
  const [ searchQuery, setSearchQuery ] = useState('');
  const [ galleryIndex, setGalleryIndex ] = useState(0);
  const [ notFound, toggleNotFound ] = useState(false);

  useEffect(() => {
    const fetchProperty = async() => {
      const response = await client.getByUID('property', uid);

      if (response) {
        console.log(response);
        return setProperty(response);
      } else {
        console.warn(`No property was found with UID ${uid}`);
        return toggleNotFound(true);
      }
    };

    fetchProperty();
  }, [ uid ]);

  const navigatePrevImage = () => {
    if (galleryIndex > 0) return setGalleryIndex(galleryIndex - 1);
    return setGalleryIndex(property.data.images.length - 1);
  };

  const navigateNextImage = () => {
    if (galleryIndex < property.data.images.length - 1) return setGalleryIndex(galleryIndex + 1);
    return setGalleryIndex(0);
  };

  if (property) {
    const { title, city, state, county, description, details } = property.data;

    return (
      <main className="pb-20 bg-white overflow-hidden">
        <div className="block relative overflow-hidden" id="media-gallery">
          <picture className="object-center">
            <source media="(min-width: 769px)" srcSet={property.data.images[galleryIndex].image.url} />
            <source srcSet={property.data.images[galleryIndex].image.mobile.url} />
            <img src={property.data.images[galleryIndex].image.url} alt="View of the Property" />
          </picture>
        
          <div className="absolute left-0 md:left-2 inset-y-0 flex flex-col justify-center">
            <button onClick={navigatePrevImage}>
              <svg className="svg-shadow h-10 w-10 text-white" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24"><path d="M15 19l-7-7 7-7"></path></svg>
            </button>
          </div>
          <div className="absolute right-0 md:right-2 inset-y-0 flex flex-col justify-center">
            <button onClick={navigateNextImage}>
              <svg className="svg-shadow h-10 w-10 text-white" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24"><path d="M9 5l7 7-7 7"></path></svg>
            </button>
          </div>
        </div>

        <div className="max-w-screen-lg mx-auto py-10 md:py-16 md:pb-10 px-4 md:px-0">
          <div className="max-w-2xl font-bold text-4xl text-gray-900">
            {RichText.render(title, linkResolver)}
          </div>
          <h3 className="font-medium mt-3 text-gray-500">{city}, {state} | {county} County</h3>
          <div className="max-w-4xl mt-3 text-sm text-gray-900 leading-normal" id="description">
            {RichText.render(description, linkResolver)}
          </div>
        </div>

        {details[0] && 
        <div className="max-w-screen-lg mx-auto pb-16 px-4 md:px-0">
          <h3 className="mb-6 text-2xl font-semibold text-gray-700">
            Property Details
          </h3>

          <div id="details-table" class="bg-white shadow sm:rounded-lg overflow-hidden">
            <div class="px-4 py-5 border-b border-gray-200 sm:px-6">  
              <div class="mt-0 md:mt-3 relative rounded-md shadow-sm">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg className="z-10 h-5 w-5 text-gray-400" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                  </svg>
                </div>

                <input 
                  id="search"
                  placeholder="Type to search property details..."
                  onChange={(evt) => setSearchQuery(evt.target.value)}
                  class="relative form-input block w-full pl-10 sm:text-sm sm:leading-5"
                />
              </div>
            </div>

            <div>
              <dl className="overflow-scroll">
                {Object.keys(details[0]).filter((key) => key.replace(/_/g, ' ').includes(searchQuery.toLowerCase()) || searchQuery === '').map((detail, index) => {                
                  return details[0][detail] !== null 
                  ? (<div key={index} class={`${index % 2 === 0 ? 'bg-gray-50' : 'bg-white'} px-4 py-3 md:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6`}>
                      <dt class="text-sm leading-5 font-semibold text-gray-700 capitalize">
                        {detail.replace(/_/g, " ")}
                      </dt>
                      <dd class="mt-1 text-sm leading-5 text-gray-800 sm:mt-0 sm:col-span-2">
                        {details[0][detail]}
                      </dd>
                    </div>)
                  : null
                })}
              </dl>
            </div>
          </div>
        </div>}

        <div className="max-w-screen-lg mx-auto px-4 md:px-0 pb-16">
          <div class="px-6 py-6 bg-red-700 rounded-lg md:py-8 md:px-10 xl:flex xl:items-center">
            <div class="xl:w-0 xl:flex-1">
              <h2 class="text-2xl leading-8 font-extrabold tracking-tight text-white sm:text-3xl sm:leading-9">
                Want drone footage of this property?
              </h2>
              <p class="mt-3 max-w-3xl text-lg leading-6 text-red-200">
                Enter your email for access to HD footage of this property.
              </p>
            </div>
            <div class="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-6">
              <SimpleEmailCapture btnText="Get Access" btnColor="blue" btnHue="600" />

              <p class="mt-3 text-sm leading-5 text-red-200">
                We care about your privacy and will never share your email.
              </p>
              {/* do we need this clear element? */}
              <div class="_clear-element">
              </div>
            </div>
            {/* do we need this thank form? */}
            <div class="_form-thank-you">
            </div>
          </div>
        </div>

        <div className="max-w-screen-lg mx-auto px-4 md:px-0">
          <h3 className="mb-6 text-2xl font-semibold text-gray-700">
            Property Photos
          </h3>
          <div className="md:px-5 lg:px-0 grid gap-3 md:grid-cols-2 lg:grid-cols-3" id="image-container">
            {property.data.images.map((image, index) => {
              return (
                <LightboxImage key={index} image={image} />
              );
            })}
          </div>
        </div>
      </main>
    );
  } else if (notFound) {
    return <NotFound />;
  } else {
    return null;
  }
}

export default Property;


const LightboxImage = function({ image }) {
  const [ isLightboxOpen, setIsLightboxOpen ] = useState(false);

  return (    
    <div class="rounded-sm shadow-md overflow-hidden"> {/* z-0 relative */}
      <div class="cursor-pointer">
        <img onClick={() => setIsLightboxOpen(true)} className="w-full h-full object-cover transition duration-300 ease-in-out transform hover:scale-105 hover:opacity-75" src={image.image.thumbnail.url} alt={image.image.alt} />
      </div>

      <Transition
        show={isLightboxOpen}
        enter="transition ease-out duration-200 transform"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition ease-in duration-75 transform"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div class="fixed inset-0 w-screen h-full min-h-screen z-50 bg-black bg-opacity-75 transform" onClick={() => setIsLightboxOpen(false)}>
          <div class="absolute w-11/12 md:w-9/12 top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2">
            <img className="w-full h-full" src={image.image.url} alt={image.image.alt} />
          </div>
          <svg className="absolute cursor-pointer w-6 h-6 top-4 right-4 text-white hover:text-gray-200 transition duration-150 ease-in-out" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M6 18L18 6M6 6l12 12"></path></svg>
        </div>
      </Transition>
    </div>
  );
};