import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Prismic from 'prismic-javascript';
import { client } from '../../prismic-configuration';
import NotFound from '../NotFound';

import PropertyCard from '../properties/PropertyCard';

function PropertiesPreview() {
  const [ properties, setProperties ] = useState(null);
  const [ notFound, toggleNotFound ] = useState(false);

  useEffect(() => {
    const fetchProperties = async() => {
      const type = 'property';
      const response = await client.query(
        Prismic.Predicates.at( 'document.type', type ),
        { pageSize: 3 }
      );

      if (response) {
        return setProperties(response.results);
      } else {
        console.warn(`No documents were found of type ${type}.`);
        toggleNotFound(true);
      }
    };

    fetchProperties();
  }, []);

  if (properties) {
    return (
      <section id="featured-properties" class="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div class="absolute inset-0">
          <div class="bg-white h-1/3 sm:h-2/3"></div>
        </div>
        <div class="relative max-w-7xl mx-auto">
          <div class="text-center">
            <h2 class="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
              Featured Properties
            </h2>
            <p class="mt-3 max-w-2xl mx-auto text-xl leading-7 text-gray-500 sm:mt-4">
              {/* Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa libero labore natus atque, ducimus sed. */}
              We have a wide variety of properties available across 6 states in the US. Browse below to find the perfect one for you.
            </p>
          </div>

          <div class="mt-12 grid gap-8 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
            {properties.sort((a, b) => {
                if (a.data.sold && !b.data.sold) return 1;
                else if (!a.data.sold && b.data.sold) return -1;
                else return 0;
              }).map((property) => {
                return <PropertyCard property={property} />;
              })
            }
          </div>

          <div class="mt-12 flex justify-center">
            <div class="inline-flex rounded-md shadow">
              <Link to="/properties" class="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-bold rounded-md text-white bg-red-600 shadow-md hover:shadow-lg hover:bg-red-700 focus:outline-none focus:shadow-outline transition duration-200 ease-in-out">
                See More Properties
                <svg class="-mr-1 ml-3 h-5 w-5 text-white" fill="currentColor" viewBox="0 0 20 20">
                  <path fill-rule="evenodd" d="M11 3a1 1 0 100 2h2.586l-6.293 6.293a1 1 0 101.414 1.414L15 6.414V9a1 1 0 102 0V4a1 1 0 00-1-1h-5zM5 5a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2v-3a1 1 0 10-2 0v3H5V7h3a1 1 0 000-2H5z" clip-rule="evenodd"/>
                </svg>
              </Link>
            </div>
          </div>
        </div>
      </section>
    )
  } else if (notFound) {
    return <NotFound />;
  } else {
    return null;
  }
}

export default PropertiesPreview;
