import React from 'react';
import Transition from '../util/Transition';

function Alert({ type, isShowing, setShowing }) {
  return (
    <Transition
      show={isShowing}
      enter="duration-150 ease-out transform"
      enterFrom="opacity-0 scale-95 translate-y-5"
      enterTo="opacity-100 scale-100 translate-y-0"
      leave="duration-100 ease-in transform"
      leaveFrom="opacity-100 scale-100 translate-y-0"
      leaveTo="opacity-0 scale-95 translate-y-5"
    >
      <div class={`${ type === 'success' ? 'bg-green-50' : 'bg-red-50'} fixed bottom-0 md:bottom-4 md:right-3 w-full md:w-3/5 rounded-md shadow-lg p-4`}>
        <div class="flex">
          <div class="flex-shrink-0">
            { type === "success" 
              ? <svg class="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clip-rule="evenodd"/>
                </svg>
              : <svg class="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clip-rule="evenodd"/>
                </svg>
            }
          </div>
          <div class="ml-3">
            <p class={`text-sm leading-5 font-medium ${type === "success" ? 'text-green-800' : 'text-red-800'}`}>
              {type === "success" ? 'Successfully submitted' : 'Oops! Something went wrong. Please try again' }
            </p>
          </div>
          <div class="ml-auto pl-3">
            <div class="-mx-1.5 -my-1.5">
              <button onClick={() => setShowing(false)} class={`${type === "success" ? 'text-green-500 hover:bg-green-100 focus:bg-green-100' : 'text-red-500 hover:bg-red-100 focus:bg-red-10'} inline-flex rounded-md p-1.5 focus:outline-none transition ease-in-out duration-150`}>
                <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"/>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  )
}

export default Alert;
