export const businessInfo = {
  name: 'US Land Shop',
  phone: '512-866-6699',
  email: 'info@uslandshop.com'
};

export const navigation = {
  links: [
    {
      title: 'Home',
      href: '/'
    },
    {
      title: 'Properties',
      href: '/properties'
    },
    {
      title: 'Contact Us',
      href: '/contact'
    },
    // {
    //   title: 'Contact',
    //   href: '/contact'
    // },
  ]
};

export const testimonials = [
  {
    name: 'Judith Black 1',
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo expedita voluptas culpa sapiente alias molestiae. Numquam corrupti in laborum sed rerum et corporis.',
    about: 'CEO, Tuple',
    avatarUrl: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
  },
  {
    name: 'Judith Black 2',
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo expedita voluptas culpa sapiente alias molestiae. Numquam corrupti in laborum sed rerum et corporis.',
    about: 'CEO, Tuple',
    avatarUrl: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
  },
  {
    name: 'Judith Black 3',
    description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo expedita voluptas culpa sapiente alias molestiae. Numquam corrupti in laborum sed rerum et corporis.',
    about: 'CEO, Tuple',
    avatarUrl: 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
  },
];