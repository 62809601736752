import React from 'react';
import AboutUsImg from '../../assets/us-land-shop-about-us.jpg';

function About() {
  return (
    <section className="relative block bg-gray-50 border-t-2 border-solid border-gray-300 overflow-hidden pt-0 md:pt-16 pb-16 lg:py-24" id="about">
      <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-screen-xl">
        <svg className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4" width="404" height="784" fill="none" viewBox="0 0 404 784">
          <defs>
            <pattern id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect x="0" y="0" width="4" height="4" className="text-gray-200" fill="currentColor" />
            </pattern>
          </defs>
          <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
        </svg>

        <div className="relative mt-0 lg:mt-12 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
          <div className="mt-10 -mx-4 relative lg:mt-0">
            <img className="h-64 lg:h-auto md:rounded-md shadow-xl object-cover object-top relative mx-auto" width="490" src={AboutUsImg} alt="" />
          </div>

          <div className="relative mt-10 lg:mt-0">
            <h4 className="text-2xl leading-8 font-extrabold text-gray-900 tracking-tight sm:text-3xl sm:leading-9">
              About Us
            </h4>
            <p className="mt-3 text-lg leading-7 text-gray-500">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur minima sequi recusandae, porro maiores officia assumenda aliquam laborum ab aliquid veritatis impedit odit adipisci optio iste blanditiis facere. Totam, velit.
            </p>
            <p className="mt-3 text-lg leading-7 text-gray-500">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Pariatur minima sequi recusandae, porro maiores officia assumenda aliquam laborum ab aliquid veritatis impedit odit adipisci optio iste blanditiis facere. Totam, velit.
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default About;
