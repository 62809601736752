import React from 'react';

import Banner from './Banner';
import PropertiesPreview from './PropertiesPreview';
import Testimonials from './Testimonials';
import About from './About';

function Home() {
  return (
    <main>
      <Banner />
      <PropertiesPreview />
      <Testimonials />
      <About />
    </main>
  )
}

export default Home;
