import React from 'react';
import { Link } from 'react-router-dom';
import { RichText } from 'prismic-reactjs';

function PropertyCard({ property }) {
  const { uid } = property;
  const { sold, title, description, price, images, state, county } = property.data;

  return (
    <article class="flex flex-col rounded-lg shadow-lg hover:shadow-2xl transition-all duration-200 ease-in-out transform hover:-translate-y-2 overflow-hidden">
      <Link to={`/properties/${uid}`}>
        <div class="flex-shrink-0 relative">
          {sold && 
            <div className="absolute w-full h-full flex flex-col justify-center items-center bg-red-600 opacity-75">
              <h4 className='text-3xl tracking-wide font-bold text-white'>SOLD!</h4>
            </div>
          }
          <img class="h-48 w-full object-cover" src={images[0].image.mobile.url} alt="Property Thumbnail" />
        </div>
        <div class="flex-1 bg-white p-6 flex flex-col justify-between">
          <div class="flex-1">
            <p class="text-sm leading-5 font-medium text-red-600">
              {county} County | {state}
            </p>
            <div class="block">
              <h3 class="mt-2 text-xl leading-7 font-semibold text-gray-900">
                { RichText.asText(title).length >= 65 ? RichText.asText(title).substring(0, 65) + '...' : RichText.asText(title) }
              </h3>
              <p class="mt-3 text-base leading-6 text-gray-500">
                { RichText.asText(description).length >= 120 ? RichText.asText(description).substring(0, 120) + '...' : RichText.asText(description) }
              </p>
            </div>
          </div>

          <div class="mt-5 flex items-center justify-between">
            <div className="text-gray-700 text-semibold text-xl">
              {price}
            </div>
            <div className="">
              <button className="inline-flex items-center justify-center px-5 py-2 border border-transparent text-base leading-6 font-bold rounded-md text-white bg-red-600 hover:bg-red-700 shadow-sm focus:outline-none focus:shadow-outline transition duration-200 ease-in-out">
                View
              </button>
            </div>
          </div>
        </div>
      </Link>
    </article>
  )
}

export default PropertyCard;
