import React from 'react';
import { businessInfo } from '../../site.config';

import ContactBG from '../../assets/us-land-shop-contact-bg.jpg';
import ContactFormCapture from '../../components/email/ContactFormCapture.jsx';

function Contact({ setAlertShowing }) {
  return (
    <main>
      <div class="relative bg-white">
        <div class="lg:absolute lg:inset-0">
          <div class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <img class="h-56 w-full object-cover object-left lg:absolute lg:h-full" src={ContactBG} alt="US Land Shop Contact Us Background" />
          </div>
        </div>
        
        <div class="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div class="lg:pr-8">
            <div class="max-w-md mx-auto sm:max-w-lg lg:mx-0">
              <h2 class="text-3xl leading-9 font-extrabold tracking-tight sm:text-4xl sm:leading-10">
                Contact us
              </h2>
              <p class="mt-4 text-lg leading-7 text-gray-500 sm:mt-3">
                We’d love to hear from you! Send us a message using the form below, or reach out to us by phone or email.
              </p>
              
              <div class="mt-6 text-base leading-6 text-gray-500">
                <dt class="sr-only">Phone number
                </dt>
                <dd class="flex">
                  <svg class="flex-shrink-0 h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"/>
                  </svg>
                  <span class="ml-3">
                    { businessInfo.phone }
                  </span>
                </dd>
              </div>
              <div class="mt-3 text-base leading-6 text-gray-500">
                <dt class="sr-only">Email
                </dt>
                <dd class="flex">
                  <svg class="flex-shrink-0 h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"/>
                  </svg>
                  <span class="ml-3">
                    { businessInfo.email }
                  </span>
                </dd>
              </div>

              <ContactFormCapture setAlertShowing={setAlertShowing} />
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Contact;
