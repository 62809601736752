import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Transition from '../util/Transition';

import { navigation, businessInfo } from '../site.config';

function Header() {
  const [ isOpen, setIsOpen ] = useState(false);

  return (
    // <!-- This example requires Tailwind CSS v1.4.0+ -->
    <header class="relative bg-white z-10">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 border-b-2 border-gray-100">
        <div class="flex justify-between items-center  py-6 md:justify-start md:space-x-10">
          <div class="lg:w-0 lg:flex-1">
            <Link to="/" class="flex font-bold tracking-wide hover:text-gray-500">
              {/* <img class="h-8 w-auto sm:h-10" src="/img/logos/workflow-mark-on-white.svg" alt="Workflow" /> */}
              <span role="img" aria-label="US Flag Emoji">🇺🇸</span>&nbsp;
              { businessInfo.name }
            </Link>
          </div>
          <div class="-mr-2 -my-2 md:hidden">
            <button onClick={() => setIsOpen(true)} type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
              <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16"/>
              </svg>
            </button>
          </div>

          <nav class="hidden md:flex space-x-10">
            {navigation.links.map(link => {
              return (
                <Link key={link.title} to={link.href} class="text-base leading-6 font-medium text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition ease-in-out duration-150">
                  {link.title}
                </Link>
              );
            })}
          </nav>

          <div class="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
            <svg className="mr-2 h-6 w-6 text-red-500" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
              <path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path>
            </svg>
            <p className="inline-flex text-md">
              <a href={`tel:${businessInfo.phone}`} className="text-red-500 font-semibold">
                { businessInfo.phone }
              </a>
            </p>
          </div>
        </div>
      </div>

      {/* <!--
        Mobile menu, show/hide based on menu open state.

        Entering: "duration-150 ease-out"
          From: "opacity-0 scale-95"
          To: "opacity-100 scale-100"
        Leaving: "duration-100 ease-in"
          From: "opacity-100 scale-100"
          To: "opacity-0 scale-95"
      --> */}
      <Transition
        show={isOpen}
        enter="duration-150 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <div class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden">
          <div class="rounded-lg shadow-md">
            <div class="rounded-lg bg-white shadow-xs overflow-hidden">
              <div class="px-5 pt-4 flex items-center justify-between">
                <div>
                  {/* <img class="h-8 w-auto" src="/img/logos/workflow-mark-on-white.svg" alt="" /> */}
                  <Link 
                    to="/"
                    onClick={() => setIsOpen(false)}
                    class="flex font-bold tracking-wide hover:text-gray-500"
                  >
                    <span role="img" aria-label="US Flag Emoji">🇺🇸</span>&nbsp;
                    { businessInfo.name }
                  </Link>
                </div>
                <div class="-mr-2">
                  <button onClick={() => setIsOpen(false)} type="button" class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-500 transition duration-150 ease-in-out">
                    <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                      <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </button>
                </div>
              </div>

              <div class="px-2 pt-2 pb-3">
                {navigation.links.map((link, index) => {
                  return (
                    <Link 
                      key={link.title} 
                      to={link.href} 
                      onClick={() => setIsOpen(false)}
                      class={`${index !== 0 && 'mt-1'} block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out`}
                    >
                      {link.title}
                    </Link>
                  );
                })}
              </div>
              
              <div>
                <div class="flex items-center justify-center w-full px-5 py-3 text-center font-medium text-red-500 bg-gray-50 hover:bg-gray-100 hover:text-red-700 focus:outline-none focus:bg-gray-100 focus:text-red-700 transition duration-150 ease-in-out">
                  <svg className="mr-2 h-6 w-6 text-red-500" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor">
                    <path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path>
                  </svg>
                  <a href={`tel:${businessInfo.phone}`} className="font-semibold">
                    { businessInfo.phone }
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </header>
  )
}

export default Header;
