import React, { useState, useEffect } from 'react'
import Prismic from 'prismic-javascript';
import { client } from '../../prismic-configuration';

import PropertyCard from './PropertyCard';
import NotFound from '../NotFound';

function Properties() {
  const [ properties, setProperties ] = useState(null);
  const [ notFound, toggleNotFound ] = useState(false);
  const [ filters, setFilters ] = useState({ state: 'All', county: 'All' });

  useEffect(() => {
    const fetchProperties = async() => {
      const type = 'property';
      const response = await client.query(
        Prismic.Predicates.at( 'document.type', type )
      );

      if (response) {
        console.log(response.results);
        return setProperties(response.results);
      } else {
        console.warn(`No documents were found of type ${type}.`);
        toggleNotFound(true);
      }
    };

    // results[0].data.images[0].image.thumbnail.url

    fetchProperties();
  }, []);

  const filterProperties = (properties) => {
    return properties.filter((property) => {
      for (let filter in filters) {
        if (property.data[filter] !== filters[filter] && filters[filter] !== 'All') return false;
      }

      return true;
    });
  };

  if (properties) {
    const filteredProperties = filterProperties(properties);

    return (
      <main class="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
        <div class="absolute inset-0">
          <div class="bg-white h-1/3 sm:h-2/3"></div>
        </div>
        <div class="relative max-w-7xl mx-auto">
          <div class="text-center">
            <h2 class="text-3xl leading-9 tracking-tight font-extrabold text-gray-900 sm:text-4xl sm:leading-10">
              Properties
            </h2>
            <p class="mt-3 max-w-2xl mx-auto text-xl text-left md:text-center leading-7 text-gray-500 sm:mt-4">
              {/* Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa libero labore natus atque, ducimus sed. */}
              Our full inventory of available properties. Looking for land of a certain size, or certain state? Use our filters to quickly find what you need!
            </p>
          </div>

          <FilterBar properties={properties} setFilters={setFilters} />

          <div class="mt-12 grid gap-8 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
            {filteredProperties.length >= 1 
              ? filteredProperties.sort((a, b) => {
                if (a.data.sold && !b.data.sold) return 1;
                else if (!a.data.sold && b.data.sold) return -1;
                else return 0;
              }).map((property) => {
                return <PropertyCard property={property} />;
              })
              : <h4 className="mt-12 col-span-3 text-center text-xl">
                  We didn't find any properties that matched those criteria!
                </h4>
            }
          </div>
        </div>
      </main>
    )
  } else if (notFound) {
    return <NotFound />
  } else {
    return null;
  }
}

export default Properties;


function FilterBar({ setFilters, properties }) {
  const [ state, setState ] = useState('All');
  const [ county, setCounty ] = useState('All');
  const availableStates = Array.from(new Set(properties.map(property => property.data.state)));
  const availableCounties = Array.from(new Set(properties.map(property => property.data.county)));

  const applyFilters = (evt) => {
    evt.preventDefault();
    setFilters({ state, county });
  };

  return (
    <form className="mx-auto mt-8 md:mt-12 flex flex-col md:flex-row items-center md:items-end" onSubmit={applyFilters}>
      <div className="mr-0 md:mr-6">
        <h4 className="text-bold text-gray-800">Filter Properties by:</h4>
      </div>
      
      <div className="mt-2 md:mt-0 mr-0 md:mr-6 w-4/5 md:w-1/5">
        <label for="state" class="block text-sm font-medium leading-5 text-gray-700">
          State
        </label>
        <div class="mt-1 rounded-md shadow-sm">
          <select onChange={(evt) => setState(evt.target.value)} id="state" class="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
            <option>All</option>
            {availableStates.map((st, index) => {
              return (
                <option key={index}>{st}</option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="mt-2 md:mt-0 mr-0 md:mr-6 w-4/5 md:w-1/5">
        <label for="county" class="block text-sm font-medium leading-5 text-gray-700">
          County
        </label>
        <div class="mt-1 rounded-md shadow-sm">
          <select onChange={(evt) => setCounty(evt.target.value)} id="county" class="form-select block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5">
            <option>All</option>
            {availableCounties.map((cnty, index) => {
              return (
                <option key={index}>{cnty}</option>
              );
            })}
          </select>
        </div>
      </div>

      <div className="w-4/5 md:w-32 mt-8 md:mt-0">
        <button type="submit" class="w-full inline-flex items-center justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-indigo active:bg-red-700 transition duration-150 ease-in-out">
          <svg className="w-4 h-4 mr-2" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24"><path d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
          Search
        </button>
      </div>
    </form>
  );
}
